import { useState, useCallback, useMemo, useRef } from 'react';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';

import { useProduct } from '../../hooks';
import { useSession } from '~/modules/sessions/hooks/useSession';

import validatorSchema from '../../validators/product';
import { formDataParser } from '../../sanitizers/product';

import CustomRowHeadCells from '~/shared/components/TableList/CustomRowHeadCells';
import CustomRowOtherCells from '~/shared/components/TableList/CustomRowOtherCells';

import ReprocessDialog from '~/shared/components/Dialogs/ReprocessDialog';
import WarningDialog from '~/shared/components/Dialogs/WarningDialog';
import TableList, {
  WrapperContainer,
} from '~/shared/components/TableList/TableList';

import { tableListOptions } from '../../constantVariables/product';

function ProductsList() {
  const tableListRef = useRef();
  const { current_accesses } = useSession();
  const {
    productsListLoading,
    index,
    reprocessAll,
    reprocessUnitary,
    destroy,
  } = useProduct();

  const [showDialog, setShowDialog] = useState(false);
  const [showWarningDialog, setShowWarnigDialog] = useState(false);

  const loadProducts = useCallback(
    async (
      search = '',
      order_by = 'id',
      order = 'asc',
      page = 1,
      filter_by = ''
    ) => {
      return index({
        search,
        order_by,
        order,
        page,
        filter_by,
      });
    },
    [index]
  );

  const handleReprocess = async ({ type, indicator, start_at }) => {
    await reprocessAll({ type, indicator, start_at });
    setShowDialog(false);
  };

  const newItem = useMemo(() => {
    if (
      current_accesses.roles['farm.admin'] ||
      current_accesses.roles['farm.manager'] ||
      current_accesses.permissions['farm.products.patch']
    ) {
      return {
        label: 'Reprocessar Todos',
        handleFunction: () => {
          setShowDialog(true);
        },
      };
    }

    return null;
  }, [current_accesses]);

  const handleRemove = useCallback(
    async ({ dataObj }) => {
      const removedData = await destroy({
        dataObj: { ...dataObj },
      });

      if (removedData.uuid) {
        tableListRef.current.handleRemoveData({
          key: 'uuid',
          value: removedData,
        });
      }

      setShowWarnigDialog(false);
    },
    [destroy]
  );

  const handleReprocessUnitary = async ({ indicator, row, origin }) => {
    await reprocessUnitary({ indicator, row, origin });
    await loadProducts();
  };

  const reprocessModal = useMemo(() => {
    const auxReprocessModal = [
      {
        value: 'ecommerce',
        label: 'E-Commerce',
      },
      {
        value: 'erp',
        label: 'ERP',
      },
    ];
    return auxReprocessModal;
  }, []);

  const reprocessFields = useMemo(() => {
    const auxReprocessFields = [
      {
        value: 'product',
        label: 'Produto',
      },
      {
        value: 'stock',
        label: 'Estoque',
      },
      {
        value: 'price',
        label: 'Preço',
      },
    ];
    return auxReprocessFields;
  }, []);

  const actions = useMemo(() => {
    const auxActions = [];

    if (
      current_accesses.roles['farm.admin'] ||
      current_accesses.roles['farm.manager'] ||
      current_accesses.permissions['farm.products.delete']
    ) {
      auxActions.push({
        label: 'Remover',
        icon: <DeleteForeverIcon fontSize="small" />,
        handleFunction: async product => {
          handleRemove({ dataObj: product });
        },
      });
    }

    return auxActions;
  }, [handleRemove, current_accesses]);

  return (
    <WrapperContainer>
      <ReprocessDialog
        showDialog={showDialog}
        setShowDialog={setShowDialog}
        onSubmit={handleReprocess}
        dataParser={formDataParser}
        validatorSchema={validatorSchema}
        loading={productsListLoading}
        indicatorOptions={tableListOptions.reprocessOptions}
        typesOptions={tableListOptions.typesOptions}
        dataInput
      />
      <WarningDialog
        showDialog={showWarningDialog}
        setShowDialog={setShowWarnigDialog}
        description="Tem certeza que deseja remover o Produto?"
        onSuccess={handleRemove}
        loading={productsListLoading}
      />
      <TableList
        title=""
        newItem={newItem}
        headCells={tableListOptions.headCells}
        actions={actions}
        loadData={loadProducts}
        customRowHeadCells={CustomRowHeadCells}
        customRowOtherCells={CustomRowOtherCells}
        loading={productsListLoading}
        otherCells={tableListOptions.otherCells}
        reprocessModal={reprocessModal}
        reprocessFields={reprocessFields}
        reprocessSubmit={handleReprocessUnitary}
        ref={tableListRef}
        filtersOptions={tableListOptions.filtersOptions}
        idFilterInitial="erp_id"
      />
    </WrapperContainer>
  );
}

export default ProductsList;
